import React from 'react';

const CancelButton = ({ onClick }) => (
    <button
        onClick={onClick}
        className="text-gray-500 hover:text-gray-700 p-2 rounded-full hover:bg-gray-100"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
            />
        </svg>
    </button>
)

export default CancelButton;